@font-face {
    font-family: 'Vinila Condensed';
    src: url('../fonts/VinilaCondensed-Bold.woff2') format('woff2'), url('../fonts/VinilaCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Condensed';
    src: url('../fonts/VinilaCondensed-Regular.woff2') format('woff2'), url('../fonts/VinilaCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Condensed';
    src: url('../fonts/VinilaCondensed-Light.woff2') format('woff2'), url('../fonts/VinilaCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.condensed {
    font-family: 'Vinila Condensed';
}

.extended {
    font-family: 'Vinila Extended';
}

.font {
    &-regular {
        font-weight: normal;
    }
    &-bold {
        font-weight: bold;
    }
    &-light {
        font-weight: 300;
    }
    &-italic {
        font-style: italic;
    }
    &-black {
        font-weight: 900;
    }
    &-underline {
        text-decoration: underline;
    }
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-Light.woff2') format('woff2'), url('../fonts/VinilaExtended-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-BoldOblique.woff2') format('woff2'), url('../fonts/VinilaExtended-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-LightOblique.woff2') format('woff2'), url('../fonts/VinilaExtended-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-Bold.woff2') format('woff2'), url('../fonts/VinilaExtended-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-Black.woff2') format('woff2'), url('../fonts/VinilaExtended-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinila Extended';
    src: url('../fonts/VinilaExtended-BlackOblique.woff2') format('woff2'), url('../fonts/VinilaExtended-BlackOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
