*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

body {
    overflow-x: hidden;
    position: relative;
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: normal;
    background-color: var(--dark);
    color: #fff;
    height: 100%;
    font-size: 2.4rem;
}

a,
a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

ul,
li {
    margin: 0;
    padding: 0;
    line-height: 1;
    list-style: none;
}

img,
svg {
    display: block;
    outline: none;
    max-width: inherit !important;
    max-height: inherit !important;
}

input,
input:focus,
select,
select:focus {
    font-style: normal;
    font-weight: normal;
    outline: none !important;
    box-shadow: none !important;
}

button,
button:focus {
    font-style: normal;
    font-weight: normal;
    outline: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent;
}

a.button {
    display: block;
    position: relative;
    border: 0.3rem solid #fff;
    padding: 2rem 3.2rem;
    color: #fff;
    width: fit-content;
    font-size: 2.4rem;
    text-transform: uppercase;
    border-radius: 6rem;
    overflow: hidden;
    transition: all 0.2s $ease-in-out-quad;
    &:hover {
        color: var(--dark);
        background-color: #fff;
        transition: all 0.2s $ease-in-out-quad;
    }
    @media screen and (orientation: portrait) and (max-width: 600px) {
        font-size: 1.7rem;
        border: 0.18rem solid #fff;
        padding: 1rem 2.2rem;
    }
}

a {
    font-family: 'Vinila Condensed';
}

html {
    font-size: .54vw
}

@media screen and (orientation: portrait) and (max-width: 600px) {
    html {
        font-size: 2.94vw;
    }
    [data-aos-delay] {
        transition-delay: 0s !important;
    }
     :root {}
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        --bs-gutter-x: 3rem !important;
    }
}
