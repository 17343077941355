.header {
    position: relative;
    padding: 5rem 0;
    .logo {
        width: 100%;
    }
    .whatsapp {
        position: relative;
        width: fit-content;
        a {
            display: inline-flex;
            text-transform: uppercase;
            font-family: 'Vinila Condensed';
            line-height: 1.5;
            color: #fff;
            &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0.25rem;
                background-color: #fff;
                bottom: 0;
                transition: all 0.3s $ease-in-out-quad;
            }
            &:hover {
                color: #fff;
                &:before {
                    width: calc(100% - 3.5rem);
                    transition: all 0.3s $ease-in-out-quad;
                }
            }
        }
        img {
            width: 2.5rem;
            height: auto;
            margin-left: 1rem;
        }
    }
    @media screen and (orientation: portrait) and (max-width: 600px) {
        padding: 6rem 0;
        .logo {
            width: 21rem;
        }
        .whatsapp {
            text-align: right;
            margin-top: 1rem;
            a span {
                display: none;
            }
            img {
                width: 4.7rem;
            }
        }
    }
}
