$sm: 36em;
$md: 48em;
$lg: 62em;
$xl: 80em;
$xxl: 90em;
$hg: 100em;
$font-primary: 'Roboto',
sans-serif;
$color-name: #ff0000;
:root {
    --primary-font: #{$font-primary};
    --name-color: #{$color-name};
    --dark: #131315;
    --bs-btn-font-family: 'Vinila Condensed';
}
