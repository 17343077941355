.top {
    position: relative;
    background-image: url(../images/bg-top-desktop.jpg);
    background-size: cover;
    @media screen and (orientation: portrait) and (max-width: 600px) {
        background-image: url(../images/bg-top-portrait.jpg);
        background-size: cover;
    }
}

.intro {
    position: relative;
    padding: 8rem 0 15rem 0;
    .t1 {
        margin-bottom: 5rem;
        line-height: 1.4;
    }
    .t2,
    .t3 {
        margin-bottom: 12rem;
        line-height: 1.6;
    }
    .t3 {
        padding-left: 8rem;
        br {
            &.mobile {
                display: none;
            }
        }
    }
    svg {
        width: 4rem;
        height: auto;
    }
    .t4 {
        font-size: 4.8rem;
        padding: 2rem 0 12rem 0;
        line-height: 1.4;
    }
    .t5 {
        line-height: 1.4;
        margin-bottom: 2.5rem;
        font-size: 3.2rem;
    }
    .r2 {
        .offset-md-2 {
            padding-left: 8rem;
        }
    }
    @media screen and (orientation: portrait) and (max-width: 600px) {
        padding: 0 0 8rem 0;
        .t1 {
            font-size: 1.6rem;
            margin-bottom: 3rem;
            line-height: 1.4;
        }
        .t2,
        .t3 {
            font-size: 1.7rem;
            margin-bottom: 5rem;
            line-height: 1.6;
        }
        .t3 {
            padding-left: 0rem;
            br {
                display: none;
                &.mobile {
                    display: block;
                }
            }
        }
        svg {
            width: 4rem;
            height: auto;
            margin: 2rem 0 7rem;
        }
        .t5 {
            line-height: 1.4;
            font-size: 1.6rem;
        }
        .r2 {
            .justify-content-center {
                justify-content: flex-start !important;
            }
            .title {
                font-size: 1.8rem;
            }
            .t4 {
                font-size: 2.1rem;
                padding: 2rem 0 8rem;
                br {
                    display: none;
                }
            }
            .offset-md-2 {
                padding-left: 0;
            }
        }
    }
}
