.clients {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 0 15px 8rem 15px;
    background-color: #fff;
    .swiper {
        width: 96%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 5rem;
        .swiper-pagination {
            bottom: 0rem;
            &:before {
                background-color: var(--dark);
                border-radius: 100%;
                content: " ";
                height: 1rem;
                position: absolute;
                width: 1rem;
                left: calc(50% - 0.5rem);
                top: 0.6rem;
            }
            &-bullet {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 0;
                transform: rotate(45deg);
                margin: 0 5rem !important;
                transition: all 0.5s $ease-in-out-quad;
                &-active {
                    background: var(--dark);
                    transition: all 0.5s $ease-in-out-quad;
                }
            }
        }
    }
    .swiper-slide {
        text-align: center;
        background: #fff;
        height: calc((100% - 30px) / 2) !important;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        img {
            width: 80%;
            height: auto;
            margin: auto;
        }
    }
    @media screen and (orientation: portrait) and (max-width: 600px) {
        padding: 2rem 0 4rem 0;
        background-color: #fff;
        .swiper {
            width: 96%;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 5rem;
            .swiper-pagination {
                bottom: 0.5rem;
                display: flex;
                &:before {
                    display: none;
                }
                &-bullet {
                    margin: auto !important;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 0;
                    transform: rotate(45deg);
                    &-active {
                        background: var(--dark);
                        border-radius: 100%;
                    }
                }
            }
        }
        .swiper-slide {
            img {
                width: 90%;
            }
        }
    }
}
